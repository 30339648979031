<template>
    <div class="home">
        <header></header>
        <div class="container">
            <div class="my" v-if="user">
                <van-row gutter="20" type="flex">
                  <van-col span="10" class="flex">
                      <img :src="user.avatar" >
                      <strong class="user">{{user.real_name}}</strong>
                      </van-col>
                  <van-col span="10" class="flex" offset="2">
                      <span class="link" @click="toUrl('/record')">学分获取记录</span>
                  </van-col>
                </van-row>
                <van-row gutter="20" type="flex">
                  <van-col span="12">
                      <span class="title" style="padding-left: 10px;">总学分</span>
                      <span class="data">
                          {{user.credit}}
                      </span>
                  </van-col>
                  <van-col span="12">
                      <span class="title">总名次</span>
                      <span class="data">
                          {{user.pos}}
                      </span>
                  </van-col>
                </van-row>
            </div>
            <div class="ranking full-width with-footer">
                <van-tabs v-model="activeName" class="ranking-tabs">
                  <van-tab title="排名" name="a">
                      <div class="ranking-list">
                        <div class="ranking-item" :key="item.name" v-for="(item, index) in datalist">
                            <div class="info flex">
                                <div class="order" v-if="index==0">
                                    <img src="../assets/images/1st.jpg">
                                </div>
                                <div class="order" v-else-if="index==1">
                                    <img src="../assets/images/2nd.jpg">
                                </div>
                                <div class="order" v-else-if="index==2">
                                    <img src="../assets/images/3th.jpg">
                                </div>
                                <div class="order" v-else>{{index + 1}}</div>
                                <img :src="item.avatar">
                                <div>{{item.real_name}}</div>
                            </div>
                            <div class="score">
                                {{item.credit}}
                            </div>
                        </div>
                      </div>
                  </van-tab>
                  <!-- <van-tab title="今日排名" name="b">内容 2</van-tab> -->
                </van-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import { getRanking} from '../api/task.js';
    import { checkLogin } from '../utils/common.js';
    export default{
        data() {
            return {
                activeName: 'a',
                datalist :[],
                user: null,
            }
        },
        activated() {
            checkLogin().then(()=> {
                this.getList();
            })
        },
        methods:{
            getList(){
               getRanking().then((res)=> {
                   this.getPos(res.data);
                   this.datalist = res.data.list;
                   this.user = res.data.user;
               })
            },
            getPos(data){
                var user = data.user;
                var list = data.list;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if(item.uid == user.uid){
                        user.pos = i + 1;
                        return;
                    }
                }
                user.pos ='未上榜';
            },
            toUrl(url){
                this.$router.push(url);
            }
            
        },
        
    }
</script>

<style scoped>
    @import url('../assets/css/common.css');
header{
    height: 16.8rem;
    background-color: #ff6a26;
    overflow: hidden;
}
.home{
    min-height: 100vh;
    background-color: #fff;
}
.ranking{
    background-color: #fff;
}
.my{
    /* height: 120px; */
    margin-top: 70px;
    padding: 15px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: -4px 0px 9px 1px rgba(0, 0, 0, 0.1);
    font-size: 13px;
}
.my img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    padding-right: 5px;
}
.my .user{
    font-size: 15px;
}
.my .title{
    font-weight: bold;
}
.my .data{
    font-weight: bold;
    color: #fe6e28;
    font-size: 30px;
}
.ranking{
    margin-top: 20px;
    font-size: 18px;
}
.tab-ranking{
    border-bottom: 4px solid #f2f2f2;
}
.ranking-item{
    position: relative;
    display: flex;
    width: 34.5rem;
    margin: 0 auto;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    font-weight: bold;
    padding: 10px 10px;
    border-bottom: 1px solid #eeeeee;
}
.ranking-item .order{
    width: 24px;
    height: 24px;
    color: #999;
    font-size: 17px;
}
.ranking-item .order img{
    width: 26px;
    height: 26px;
    padding: 0;
}
.ranking-item img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0 10px 0 10px;
}
.ranking-item .score{
    font-size: 20px;
    color: #fe6e28;
}
</style>